import React, { useState, useEffect } from "react";
import A4VG from "./components/A4VG";
import { useSelector } from "react-redux";


const SchedeTaratura = ({
    readOnly, params
}) => {

    // descrizione da akonfig
    // CUSTOMER DATA -> menu a tendina con scelta se metterlo o no ( se lo metti prendi quello del customer)-> se selezionato obbligatorio mettere il customer p/n; Special release: -> dipende dal campo b38: name plate standerd-> se c'è il customer allora "standard with customer number", se no "standard without customer number: APPROVAL COMPANY: se seleziono 3.1 allora select da sap tranne sandard-> se seleziono no o altre opzioni standard w.o" 
    const configuration = useSelector(state => {
        return state.configuration;
    });

    console.log('Params', params);


    return (
        <>
            {params?.rdre === "92003" && <A4VG readOnly={readOnly} data={configuration} />}



        </>
    )
};

export default SchedeTaratura;
