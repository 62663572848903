import { Box, Button } from '@material-ui/core';
import React, { useState, useEffect } from 'react'
import { useCheckMandatoryFields, useMqXS, useValidationDiscount } from '../../../../utils/Utils';
import { useHistory } from 'react-router';
import { useSelector } from "react-redux";
import { useCheckAuthorization } from '../../../../utils/Utils';
import ExistingCodeBox from '../../../../pages/Configuration/Detail/pages/Discount/components/ExistingCodeBox';
import { useCountry } from '../../../../utils/useCountry';

const ButtonSummary = ({ params, isValidExistingCode }) => {

    const mq_xs = useMqXS();
    const history = useHistory();
    const country = useCountry();
    const auth_level = useCheckAuthorization(country);


    const applicationInfo = useSelector(state => {
        return state?.configuration?.applicationInfo
    });
    const calculatedDiscount = useSelector(state => {
        return state?.configuration?.calculatedDiscount
    });


    const discountInfo = useSelector(state => {
        return state?.configuration?.discountInfo
    });

    // return se disabled true / false
    // const review_disabled = useCheckMandatoryFields();
    const [disabled, setDisabled] = useState(true)





    const handleClick = () => {
        // section 3 -> summary
        if (params?.id_configuration) {
            history.push('/configuration/' + params.rdre + '/' + params.size + '/summary/' + params?.id_configuration)
        } else {
            history.push('/configuration/' + params.rdre + '/' + params.size + '/summary')
        }

    }


    const handleClickScheda = () => {

        if (params?.id_configuration) {
            history.push('/configuration/' + params.rdre + '/' + params.size + '/schede/' + params?.id_configuration)
        } else {
            history.push('/configuration/' + params.rdre + '/' + params.size + '/schede')
        }

    }



    useEffect(() => {



        if (!discountInfo?.some(e => e.type === 'customer') || !discountInfo?.some(e => e.type === 'sales_engineer') || !calculatedDiscount?.sales_net_price || !isValidExistingCode) {

            setDisabled(true);
        } else {
            if (auth_level === 3 && (!applicationInfo?.est_annual_qty || !discountInfo?.some(e => e.type === 'branch_manager'))) {
                setDisabled(true)
            } else {
                setDisabled(false)
            }
        }


    }, [discountInfo, applicationInfo, auth_level, calculatedDiscount, isValidExistingCode]);



    return (
        <>
            <Box mr={mq_xs ? 0 : 1}>
                <Button
                    disabled={disabled}
                    variant="contained"
                    color="inherit"
                    size={mq_xs ? 'small' : 'medium'}
                    onClick={handleClick}
                >
                    Summary
                </Button>
            </Box>
            {params?.rdre === "92003" && <Box mr={mq_xs ? 0 : 1}>
                <Button
                    disabled={disabled}
                    variant="contained"
                    color="inherit"
                    size={mq_xs ? 'small' : 'medium'}
                    onClick={handleClickScheda}
                >
                    Scheda Taratura
                </Button>
            </Box>}

        </>
    )
}

export default ButtonSummary;